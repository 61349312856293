<template>
    <span
        v-if="label"
        class="d-flex align-center caption"
    >
        <v-avatar
            v-if="valueComputed"
            size="18"
            :class="[color, 'white--text']"
        >
            {{ value }}
        </v-avatar>
        <w-icon
            v-else
            value="CIRCLE"
            :color="color"
            left
            x-small
        />
        <span
            v-if="!hideStatus"
            :class="[`${color}--text`, 'pl-1', 'text-truncate']"
        >
            {{ statusText }}
        </span>
    </span>
    <v-chip
        v-else
        :color="color"
        :small="small"
        :class="['v-chip-light-bg', `${color}--text`]"
    >
        <v-avatar
            v-if="valueComputed"
            left
            :class="[color, 'white--text']"
        >
            {{ value }}
        </v-avatar>
        <span
            v-if="!hideStatus"
            class="text-truncate"
        >
            {{ statusText }}
        </span>
    </v-chip>
</template>

<script>
import { statuses } from '@apps/questions/utils'

export default {
    name: 'QuestionsStatusLabel',
    props: {
        status: {
            type: String,
            required: true
        },
        value: {
            type: [Number, String],
            default: null
        },
        hideStatus: {
            type: Boolean,
            default: false
        },
        label: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        statusText() {
            return this.$trans('questions.statuses.' + this.status)
        },
        color() {
            const status = this.status
            let color = 'secondary'

            switch (status) {
                case statuses.PENDING:
                    color = 'warning'
                    break
                case statuses.PASSED:
                    color = 'success'
                    break
                case statuses.NOT_PASSED:
                    color = 'error'
                    break
                default:
                    color = 'secondary'
                    break
            }

            return color
        },
        valueComputed() {
            const value = this.value

            if(value === null || value === undefined) {
                return null
            }

            return `${value}`
        }
    }
}
</script>

<style lang=scss>

</style>
