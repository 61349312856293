<template>
    <div>
        <w-data-table
            outlined
            hide-default-footer
            :headers="headers"
            :title="title"
            :items="items"
            @click:row="onRowClick"
        >
            <template #icon>
                <v-btn
                    icon
                    color="primary"
                    @click="$router.go(-1)"
                >
                    <w-icon value="COM_BACK"/>
                </v-btn>
            </template>

            <template #actions>
                <v-btn
                    icon
                    color="warning"
                    @click="fetchResults"
                >
                    <w-icon value="REFRESH"/>
                </v-btn>
            </template>

            <template #item.correctReplies="{item}">
                <v-avatar
                    size="24"
                    color="success"
                    class="v-avatar-light-bg success--text"
                >
                <span class="font-weight-semibold">
                    {{ item.correctReplies }}
                </span>
                </v-avatar>
            </template>

            <template #item.incorrectReplies="{item}">
                <v-avatar
                    size="24"
                    color="error"
                    class="v-avatar-light-bg error--text"
                >
                <span class="font-weight-semibold">
                    {{ item.incorrectReplies }}
                </span>
                </v-avatar>
            </template>

            <template #item.percentage="{item}">
                <v-progress-linear
                    :value="item.percentage"
                    color="success"
                    height="16"
                >
                    <template #default="{ value }">
                        <strong>{{ value }}%</strong>
                    </template>
                </v-progress-linear>
            </template>

            <template #item.scores="{item}">
            <span class="font-weight-semibold">
                {{ item.scores | questScoresLabel }}
            </span>
            </template>

            <template #item.status="{item}">
                <div>
                    <questions-status-label
                        :status="item.status"
                        small
                    />
                </div>
            </template>
            <template #item.actions="{item}">
                <w-icon-next
                    v-if="isForMember"
                    color="primary"
                />
                <div
                    v-else
                    class="d-flex justify-end"
                >
                    <w-actions-menu
                        :items="_itemActions(item)"
                    />
                </div>
            </template>
        </w-data-table>
        <v-dialog
            v-model="resultDialog"
            scrollable
            max-width="680"
        >
            <questions-process-result
                v-if="resultItem"
                :title="resultItem.title"
                :result="resultItem.data"
                height="100vh"
                @close="resultDialog = false"
            />
        </v-dialog>
        <v-dialog
            v-model="historyDialog"
            scrollable
            max-width="680"
        >
            <questions-process-history
                v-if="historyItem"
                :title="historyItem.title"
                :loading="historyItemLoading"
                :items="historyItemData"
                height="100vh"
            >
                <template #top-actions>
                    <v-btn
                        @click="historyDialog = false"
                        icon
                        dense
                    >
                        <w-icon value="CLOSE"/>
                    </v-btn>
                </template>
            </questions-process-history>
        </v-dialog>
        <v-dialog
            v-model="detailsDialog"
            scrollable
            max-width="680"
        >
            <questions-process-details
                v-if="detailsItem"
                :title="detailsItem.title"
                :result="detailsItem.data"
                :status="detailsItem.status"
                height="100vh"
            >
                <template #top-actions>
                    <v-btn
                        @click="detailsDialog = false"
                        icon
                        dense
                    >
                        <w-icon value="CLOSE"/>
                    </v-btn>
                </template>
                <template #actions>
                    <v-btn
                        text
                        color="error"
                        @click="updateStatus(detailsItem, statuses.NOT_PASSED)"
                    >
                        <w-icon value="CLOSE" left/>
                        {{ $trans('questions.statuses.not_passed') }}
                    </v-btn>
                    <v-btn
                        text
                        color="success"
                        @click="updateStatus(detailsItem, statuses.PASSED)"
                    >
                        <w-icon value="CHECK" left/>
                        {{ $trans('questions.statuses.passed') }}
                    </v-btn>
                </template>
            </questions-process-details>
        </v-dialog>
    </div>
</template>

<script>
import {
    getQuestionsResults,
    getQuestionsResultsHistory,
    getQuestionsStatistic,
    updateQuestionsResults
} from '@apps/questions/api'
import QuestionsStatusLabel from '@apps/questions/components/QuestionsStatusLabel'
import questionsFiltersMixin from '@apps/questions/mixins/questionsFiltersMixin'
import { statuses } from '@apps/questions/utils'
import QuestionsProcessDetails from '@apps/questions/components/QuestionsProcess/QuestionsProcessDetails'
import QuestionsProcessHistory from '@apps/questions/components/QuestionsProcess/QuestionsProcessHistory'
import QuestionsProcessResult from '@apps/questions/components/QuestionsProcess/QuestionsProcessResult'

export default {
    name: 'QuestionsResults',
    mixins: [questionsFiltersMixin],
    components: { QuestionsProcessResult, QuestionsProcessHistory, QuestionsProcessDetails, QuestionsStatusLabel },
    props: {
        questionsUuid: String,
        sharedType: String,
        sharedUuid: String,
        passedType: String,
        passedUuid: String,
        authorUuid: String,
        members: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    computed: {
        isForMember() {
            return !!this.passedType &&
                !!this.passedUuid
        },
        statuses() {
            return statuses
        },
        exampleResult() {
            return this.items[0]
        },
        headers() {
            const headers = []

            const title = {
                text: this.isForMember
                    ? this.$trans('Title')
                    : this.$trans('Name'),
                value: this.isForMember
                    ? this.$trans('title')
                    : this.$trans('member'),
            }

            headers.push(title)

            this.staisticMap.forEach(o => {
                headers.push({
                    text: o.text,
                    value: o.key,
                    align: 'center'
                })
            })

            headers.push({
                text: this.$trans('Status'),
                value: 'status'
            })

            headers.push({
                value: 'actions',
                align: 'right',
                sortable: false
            })

            return headers
        },
        title() {
            if(!this.statistic) return null

            return this.statistic.title
        },
        staisticMap() {
            return [
                {
                    text: this.$trans('Correct'),
                    key: 'correctReplies',
                    color: 'success'
                },
                {
                    text: this.$trans('Incorrect'),
                    key: 'incorrectReplies',
                    color: 'error'
                },
                {
                    text: this.$trans('Without reply'),
                    key: 'withoutReply',
                    color: 'grey lighten-2'
                },
                {
                    text: this.$trans('Estimate'),
                    key: 'scores',
                    color: ''
                },
                {
                    text: `${this.$trans('Progress')}, %`,
                    key: 'percentage',
                    color: ''
                }

            ]
        }
    },
    watch: {
        detailsDialog(flag) {
            if(!flag) {
                this.detailsItem = null
            }
        },
        historyDialog(flag) {
            if(!flag) {
                this.historyItem = null
                this.historyItemData = []
            }
        },
        resultDialog(flag) {
            if(!flag) {
                this.resultItem = null
            }
        }
    },
    data: () => ({
        statistic: null,
        items: [],
        dialogComponentKey: 0,
        detailsDialog: false,
        detailsItem: null,
        resultDialog: false,
        resultItem: null,
        historyDialog: false,
        historyItemLoading: false,
        historyItem: null,
        historyItemData: []
    }),
    mounted() {
        this.fetchResults()
    },
    methods: {
        _itemFilter(item) {
            const member = this.members.find(o => {
                return o.uuid === item.passed_uuid
            })

            const filtered = {
                uuid: item.uuid,
                questions_uuid: item.questions_uuid,
                shared_type: item.shared_type,
                shared_uuid: item.shared_uuid,
                passed_type: item.passed_type,
                passed_uuid: item.passed_uuid,
                title: item.title,
                data: item.data,
                status: item.status,
                totalScores: item.data.totalScores,
                member: member
                    ? member.name
                    : `${item.passed_type}:${item.passed_uuid}`
            }

            this.staisticMap.forEach(o => {
                filtered[o.key] = item.data[o.key]
            })

            return filtered
        },
        _itemActions(item) {
            return [
                {
                    text: this.$trans('Details'),
                    color: 'primary',
                    icon: 'INFO',
                    on: {
                        click: () => {
                            this.detailsItem = item
                            this.detailsDialog = true
                        }
                    }
                },
                {
                    divider: true
                },
                {
                    text: this.$trans('questions.statuses.passed'),
                    color: 'success',
                    icon: 'CHECK',
                    on: {
                        click: async () => {
                            await this.updateStatus(item, statuses.PASSED)
                        }
                    }
                },
                {
                    text: this.$trans('questions.statuses.not_passed'),
                    color: 'error',
                    icon: 'CLOSE',
                    on: {
                        click: async () => {
                            await this.updateStatus(item, statuses.NOT_PASSED)
                        }
                    }
                },
                {
                    divider: true
                },
                {
                    text: this.$trans('History'),
                    color: 'warning',
                    icon: 'HISTORY',
                    on: {
                        click: async () => {
                            this.historyItem = item
                            this.historyDialog = true
                            await this.fetchHistory(item)
                        }
                    }
                },
            ]
        },
        onRowClick(item) {
            if(this.isForMember) {
                this.resultItem = item
                this.resultDialog = true
            } else {
                this.detailsItem = item
                this.detailsDialog = true
            }
        },
        async fetchResults() {
            this.$lprogress.begin()

            try {
                const responseResults = await getQuestionsResults({
                    questions_uuid: this.questionsUuid,
                    shared_type: this.sharedType,
                    shared_uuid: this.sharedUuid,
                    author_uuid: this.authorUuid,
                    passed_type: this.passedType,
                    passed_uuid: this.passedUuid,
                })

                if(!this.isForMember) {
                    const responseStatistic = await getQuestionsStatistic({
                        questions_uuid: this.questionsUuid,
                        shared_type: this.sharedType,
                        shared_uuid: this.sharedUuid,
                        author_uuid: this.authorUuid
                    })

                    this.statistic = responseStatistic.data.data
                }

                this.items = []

                responseResults.data.data.forEach(o => {
                    this.items.push(this._itemFilter(o))
                })
            } finally {
                this.$lprogress.end()
            }
        },
        async updateStatus(item, status) {
            this.detailsDialog = false
            this.$lprogress.begin()

            try {
                await updateQuestionsResults(item.uuid, { status })
                await this.fetchResults()
            } finally {
                this.$lprogress.end()
            }
        },
        async fetchHistory(item) {
            this.historyItemLoading = true
            this.$lprogress.begin()

            try {
                const response = await getQuestionsResultsHistory({
                    questions_uuid: item.questions_uuid,
                    shared_type: item.shared_type,
                    shared_uuid: item.shared_uuid,
                    passed_type: item.passed_type,
                    passed_uuid: item.passed_uuid,
                })

                this.historyItemData = response.data.data.data
            } finally {
                this.historyItemLoading = false
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
