<template>
    <v-card
        flat
        min-width="300"
        class="questions-process questions-process--history"
    >
        <v-card-title class="d-flex text-truncate body-1">
            <span class="text-truncate">
                <slot name="title">{{ title }}</slot>
            </span>
            <v-spacer/>
            <slot name="top-actions"></slot>
        </v-card-title>
        <v-divider/>
        <v-card-text
            v-bind="vCardTextBindings"
            class="pt-4"
        >
            <v-expansion-panels>
                <v-expansion-panel
                    v-for="(item, i) in items"
                    :key="i"
                >
                    <v-expansion-panel-header class="primary--text">
                        <span v-if="item.created">
                            {{ item.created | dateFormat('YYYY-MM-DD HH:mm:ss') }}
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <questions-process-summary
                            :result="item"
                            hide-chart
                        />
                        <div
                            v-if="item.status"
                            class="mb-4 mt-2"
                        >
                            <questions-status-label
                                :status="item.status"
                            />
                        </div>
                        <questions-process-timeline
                            :result="item"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
        <v-divider v-if="$slots.actions" />
        <v-card-actions
            v-if="$slots.actions"
            class="justify-center"
        >
            <slot name="actions"></slot>
        </v-card-actions>
        <questions-process-preloader
            :loading="loading"
        />
    </v-card>
</template>

<script>
import questionsProcessBaseMixin from '@apps/questions/components/QuestionsProcess/mixins/questionsProcessBaseMixin'
import QuestionsProcessPreloader from '@apps/questions/components/QuestionsProcess/QuestionsProcessPreloader'
import QuestionsProcessSummary from '@apps/questions/components/QuestionsProcess/QuestionsProcessSummary'
import QuestionsProcessTimeline from '@apps/questions/components/QuestionsProcess/QuestionsProcessTimeline'
import QuestionsStatusLabel from '@apps/questions/components/QuestionsStatusLabel'

export default {
    name: 'QuestionsProcessHistory',
    components: { QuestionsStatusLabel, QuestionsProcessTimeline, QuestionsProcessSummary, QuestionsProcessPreloader },
    mixins: [questionsProcessBaseMixin],
    props: {
        title: String,
        items: {
            type: Array,
            default: function () {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang=scss>

</style>
