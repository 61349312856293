import { pluralizationResolver } from '@/plugins/Trans'

export default {
    filters: {
        questDurationLabel(duration) {
            if(!duration) return null

            return duration + ' ' + pluralizationResolver('minutes', duration % 20)
        },
        questSetsLabel(sets) {
            if(!sets) return null

            return sets + ' ' + pluralizationResolver('questions', sets % 20)
        },
        questScoresLabel(scores, totalScores) {
            if(!scores) return null

            const label = totalScores
                ? `${scores} / ${totalScores}`
                : scores

            return label + ' ' + pluralizationResolver('scores', scores % 20)
        }
    }
}
