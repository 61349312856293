<template>
    <component
        :is="componentName"
        :typeof="type"
        :value="value"
        :score="score"
        :reply="reply"
        v-bind="componentBindings[type]"
        @input="onInput"
        @process="onProcess"
    >
        <slot></slot>
    </component>
</template>

<script>
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'

export default {
    name: 'QuestionsTypeInput',
    mixins: [ questionsTypeBasePropsMixin ],
    components: {
        TrueFalseInput: () => import('./TrueFalseInput'),
        TrueFalseProcess: () => import('./TrueFalseProcess'),
        TrueFalseResult: () => import('./TrueFalseResult'),
        SingleAnswerInput: () => import('./AnswersVariantInput'),
        SingleAnswerProcess: () => import('./AnswersVariantProcess'),
        SingleAnswerResult: () => import('./AnswersVariantResult'),
        MultipleAnswersInput: () => import('./AnswersVariantInput'),
        MultipleAnswersProcess: () => import('./AnswersVariantProcess'),
        MultipleAnswersResult: () => import('./AnswersVariantResult'),
        FreeAnswerInput: () => import('./FreeAnswerInput'),
        FreeAnswerProcess: () => import('./FreeAnswerProcess'),
        FreeAnswerResult: () => import('./FreeAnswerResult'),
        FillTextInput: () => import('./FillTextInput'),
        FillTextProcess: () => import('./FillTextProcess'),
        FillTextResult: () => import('./FillTextResult')
    },
    computed: {
        isProcess() {
            return !!this.$listeners.process
        },
        isResult() {
            return !!this.reply
        },
        componentName() {
            let state = this.isProcess ? 'process' : 'input'

            if(this.isResult) {
                state = 'result'
            }

            return this.type.replaceAll('_', '-') + '-' + state
        },
        componentBindings() {
            return {
                true_false: {},
                single_answer: {
                    singleSelect: true
                },
                multiple_answers: {
                    singleSelect: false
                },
                free_answer: {},
                fill_text: {},
            }
        }
    },
    methods: {
        onInput(value) {
            this.$emit('input', value)
        },
        onProcess(data) {
            this.$emit('process', data)
        }
    }

}
</script>

<style lang=scss>

</style>
