<template>
    <div>
        <div
            v-if="showFilterBar"
            class="theme-outline rounded pa-4 mb-4"
        >
            <v-switch
                v-model="showSkipped"
                dense
                :label="$trans('questions.hints.show_skipped')"
                hide-details
                class="mt-0"
            />
        </div>
        <v-timeline
            dense
            class="timeline-custom-dense timeline-custom-dots"
        >
            <questions-process-timeline-item
                v-for="item in activityItems"
                :key="item.timestamp"
                :item="item"
            />
        </v-timeline>
    </div>
</template>

<script>
import QuestionsProcessTimelineItem
from '@apps/questions/components/QuestionsProcess/QuestionsProcessTimeline/QuestionsProcessTimelineItem'
export default {
    name: 'QuestionsProcessTimeline',
    components: { QuestionsProcessTimelineItem },
    props: {
        result: Object
    },
    computed: {
        showFilterBar() {
            return this.hasSkippedQuestions
        },
        hasSkippedQuestions() {
            let flag = false

            for (let i = 0; i < this.result.activity.length; i++) {
                const activity = this.result.activity[i]

                if(activity.skipped) {
                    flag = true

                    break
                }
            }

            return flag
        },
        activityItems() {
            const activityItems = []

            for (let i = 0; i < this.result.activity.length; i++) {
                const activity = this.result.activity[i]

                if(activity.skipped && !this.showSkipped) {
                    continue
                }

                let reply = null

                if(!activity.skipped) {
                    reply = this.result.replies.find(o => o.uuid === activity.uuid)
                }

                activityItems.push({
                    ...activity,
                    reply
                })
            }

            return activityItems
        }
    },
    data: () => ({
        showSkipped: false
    })
}
</script>

<style lang=scss>

</style>
