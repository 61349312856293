<template>
    <v-card
        flat
        min-width="300"
        class="questions-process questions-process--details"
    >
        <v-card-title class="d-flex text-truncate body-1">
            <span class="text-truncate">
                <slot name="title">{{ title }}</slot>
            </span>
            <v-spacer/>
            <slot name="top-actions"></slot>
        </v-card-title>
        <v-divider/>
        <v-card-text
            v-bind="vCardTextBindings"
        >
            <questions-process-summary
                :result="result"
            />
            <div
                v-if="status"
                class="text-center mb-2"
            >
                <questions-status-label
                    :status="status"
                />
            </div>
            <questions-process-timeline
                :result="result"
            />
        </v-card-text>
        <v-divider/>
        <v-card-actions class="justify-center">
            <slot name="actions"></slot>
        </v-card-actions>
        <questions-process-preloader
            :loading="loading"
        />
    </v-card>
</template>

<script>
import questionsProcessBaseMixin from '@apps/questions/components/QuestionsProcess/mixins/questionsProcessBaseMixin'
import QuestionsProcessPreloader from '@apps/questions/components/QuestionsProcess/QuestionsProcessPreloader'
import QuestionsProcessSummary from '@apps/questions/components/QuestionsProcess/QuestionsProcessSummary'
import QuestionsProcessTimeline from '@apps/questions/components/QuestionsProcess/QuestionsProcessTimeline'
import QuestionsStatusLabel from '@apps/questions/components/QuestionsStatusLabel'

export default {
    name: 'QuestionsProcessDetails',
    components: { QuestionsStatusLabel, QuestionsProcessTimeline, QuestionsProcessSummary, QuestionsProcessPreloader },
    mixins: [questionsProcessBaseMixin],
    props: {
        title: String,
        result: Object,
        status: String,
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang=scss>

</style>
