<template>
    <v-timeline-item
        small
        :color="color"
    >
        <div
            class="d-flex align-center"
        >
            <h4 class="font-weight-semibold me-1">
                {{ item.title }}
            </h4>
            <v-spacer/>
            <small class="text-no-wrap">{{ item.timestamp | dateFormat('HH:mm:ss') }}</small>
        </div>
        <div class="caption">
            <span v-if="!isSkipped">
                {{ $trans('questions.types.' + item.reply.type) }}
                <b><em>({{ item.reply.defaultScore | questScoresLabel }})</em></b>
            </span>
            <span v-else>
                {{ $trans('Skipped') }}
            </span>
        </div>
        <div
            v-if="!isSkipped"
            class="mt-2"
        >
            <questions-type-input
                :value="item.reply.options"
                :type="item.reply.type"
                :reply="item.reply.reply"
            />
        </div>
    </v-timeline-item>
</template>

<script>
import QuestionsTypeInput from '@apps/questions/components/QuestionsType/QuestionsTypeInput'
import questionsFiltersMixin from '@apps/questions/mixins/questionsFiltersMixin'
export default {
    name: 'QuestionsProcessTimelineItem',
    mixins: [questionsFiltersMixin],
    components: { QuestionsTypeInput },
    props: {
        item: Object
    },
    computed: {
        isSkipped() {
            return this.item.skipped
        },
        color() {
            if(this.isSkipped) {
                return 'secondary'
            }

            return this.item.reply.passed
                ? 'success'
                : 'error'
        }
    }
}
</script>

<style lang=scss>

</style>
